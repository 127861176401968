<template>
  <div class="main-box">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-refresh" @click="rsyncData(1)">同步小程序订阅消息</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        size="mini">
      <el-table-column
          prop="title"
          label="消息类型">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="mail_status"-->
<!--          label="站内信"-->
<!--          align="left"-->
<!--          width="300">-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch :value="scope.row.mail_status==1" @change="changeStatus(scope.row.template_type, 1, scope.row.mail_status==1?0:1)" active-text="关闭" inactive-text="开启"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="wechat_status"-->
<!--          label="公众号消息模板"-->
<!--          align="left"-->
<!--          width="300">-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch :value="scope.row.wechat_status==1" @change="changeStatus(scope.row.template_type,2,scope.row.wechat_status==1?0:1)" active-text="关闭" inactive-text="开启"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="applet_status"
          label="小程序订阅消息"
          align="left">
        <template slot-scope="scope">
          <div style="padding: 10px">
            <el-switch :value="scope.row.applet_status==1" @change="changeStatus(scope.row.template_type,3,scope.row.applet_status==1?0:1)" active-text="关闭" inactive-text="开启"></el-switch>
            <span v-if="scope.row.applet_content" class="mg-lf-10">模板ID：{{scope.row.applet_content.tmpId}}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
      }
      this.$api.merchant.messageIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    changeStatus(type,ctype,status) {
      this.$api.merchant.messageShenhe({type: type,ctype:ctype, status: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.getList()
    },
    rsyncData(type) {
      this.showLoading()
      this.$api.merchant.messageRsync({type:type}, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
